import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import CartCtx from '../CartCtx'
import ProductCard from '../categories/ProductCard';
import { isIterableArray } from '../block-slider/utils';

import '../../scss/components/main/searchLoader.scss'


export default class SearchResults extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: queryString.parse(window.location.search).q || '',
            page: this.props.match.params.page,
            products: null,
            pagination: null,
            message: '',
            isLoading: false
        };
        document.title = 'Результаты поиска | Галактика';
    }

    static contextType = CartCtx;

    changePage(num) {
        this.setState({
            page: num
        })
        setTimeout(() => {
            this.fetchProducts()
        }, 300)
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.setState({
                search: queryString.parse(window.location.search).q || '',
                page: this.props.match.params.page,
            });
            this.fetchProducts()
        }
    }
    componentDidMount() {
        this.fetchProducts()
    }

    fetchProducts() {
        this.setState({
            isLoading: true
        })
        const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

        axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-search`, {
            'str': this.state.search,
            'page': this.props.match.params.page,
            tc_id: selectedCenterId
        })
            .then(response => {

                const listWithVariable = response.data.data[0].filter(item => (item?.char?.length > 0 || item.color !== null))

                this.setState({
                    isProductsHasVariable: isIterableArray(listWithVariable),
                    products: response.data.data[0] || null,
                    pagination: response.data.data[1] || null
                })
                if (this.state.products !== null) {
                    this.setState({
                        message: ``
                    })
                } else {
                    this.setState({
                        message: `По запросу "${this.state.search}" ничего не найдено`
                    })
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }


    hideInfoModal() {
        this.setState({
            infoCharModal: false
        })
    }


    render() {
        return (
            <>
                <div className="container catalog">
                    {
                        this.state.isLoading &&
                        <div className='preloader'>
                            <div className='image-spin' />
                            <div className='image-spin right' />
                        </div>
                    }
                    {
                        !isIterableArray(this.state.products) && !this.state.isLoading &&
                        <h4 style={{ textAlign: 'center', display: 'block', color: '#999', marginTop: 30 }}>
                            По запросу "{this.state.search}" ничего не найдено
                        </h4>
                    }

                    {isIterableArray(this.state.products) &&
                        <>
                            <h2 style={{ textAlign: 'center', display: 'block', color: '#999', marginTop: 30 }}>
                                Результаты поиска: {this.state.search}
                            </h2>
                            <div className="catalog__wrap" style={{ paddingTop: 30, minHeight: 540 }}>
                                <div className="catalog-list" style={{ width: '100%' }}>
                                    <div className="catalog-list__wrap">

                                        {this.state.products.map((product, index) => (
                                            <ProductCard
                                                key={index}
                                                product={product}
                                                context={this.context}
                                                isProductsHasVariable={this.state.isProductsHasVariable}
                                            />
                                        ))}
                                    </div>
                                    {this.state.pagination !== null &&
                                        <div className="catalog-list__navigation">
                                            {this.state.pagination.lastPage > 1 &&
                                                <div className="pagination">
                                                    <ul className="pagination__wrap">
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page > 1 ? this.state.page - 1 : 1) }}>
                                                                <i className="i-arrow prev"><span></span></i>
                                                            </Link>
                                                        </li>

                                                        {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/1?q=${this.state.search}`} onClick={() => { this.changePage(1) }}>
                                                                    <b>1</b>
                                                                </Link>
                                                            </li>
                                                        }

                                                        {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                                                            <li className="pagination__item"><b>...</b></li>
                                                        }



                                                        {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/${this.state.page * 1 - 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page - 2) }}>
                                                                    <b>{this.state.page * 1 - 2}</b>
                                                                </Link>
                                                            </li>
                                                        }
                                                        {this.state.page > 1 &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/${this.state.page * 1 - 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 - 1) }}>
                                                                    <b>{this.state.page * 1 - 1}</b>
                                                                </Link>
                                                            </li>
                                                        }
                                                        <li className="pagination__item active">
                                                            <span className="link">
                                                                <b>{this.state.page}</b>
                                                            </span>
                                                        </li>
                                                        {this.state.page < this.state.pagination.lastPage &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/${this.state.page * 1 + 1}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 1) }}>
                                                                    <b>{this.state.page * 1 + 1}</b>
                                                                </Link>
                                                            </li>
                                                        }
                                                        {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/${this.state.page * 1 + 2}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page * 1 + 2) }}>
                                                                    <b>{this.state.page * 1 + 2}</b>
                                                                </Link>
                                                            </li>
                                                        }

                                                        {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                                                            <li className="pagination__item"><b>...</b></li>
                                                        }

                                                        {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                                                            <li className="pagination__item">
                                                                <Link className="link" to={`/search/${this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.pagination.lastPage) }}>
                                                                    <b>{this.state.pagination.lastPage}</b>
                                                                </Link>
                                                            </li>
                                                        }
                                                        <li className="pagination__item">
                                                            <Link className="link" to={`/search/${this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage}?q=${this.state.search}`} onClick={() => { this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage) }}>
                                                                <i className="i-arrow next"><span></span></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        );
    }
}
